.your-account-main {
	.table-block {
		border: 1px solid #E4E4E4;
		background: #ffffff;
		padding: 20px;
		border-radius: 14px;
		p {
			//styleName: Heading/H2;
			font-family: Euclid Circular A;
			font-size: 24px;
			font-weight: 600;
			line-height: 30.43px;
			text-align: left;
			color: #141414;
		}
		.title-block {
			margin-bottom: 20px;
		}
	}
	.wallet-content-header__title {
		display: flex;
		align-items: center;
		p {
			padding: 0 15px;
		}
		span {
			height: 24px;
			padding: 6px;
			border-radius: 20px;
			border: .8px solid #0071ab;
			background: #f1f7fc;
			font-family: Euclid Circular A;
			font-size: 12px;
			font-weight: 400;
			line-height: 15px;
			letter-spacing: 0;
			text-align: center;
			color: #0071ab;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
.table--your-account-main {
	.td__text___data {
		color:#141414 !important;
	}
	.td__status.yellow {
		border-color: #ff9f0a;
		background: #feeceb;
		background: rgba(255, 251, 240, 1);
		color: rgba(255, 181, 0, 1);
	}
	.td__status.blue {
		border-color: #0071AB;
		background: rgba(241, 247, 252, 1);
		color: #0071AB;
	}
	.td__status.red {
		border-color: #fe0000;
		border-color: var(--additional-error);
		background: #feeceb;
		background: var(--transparent-red);
		color: #fe0000;
		color: var(--additional-error);
	}
	.td__status {
		border-radius: 20px;
		border: .8px solid transparent;
		background: transparent;
		padding: 4px 6px;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		white-space: nowrap;
	}
	.tr {
		-ms-grid-columns: 0.5fr 0.8fr 1.2fr 1fr 1fr;
		grid-template-columns: 0.5fr 0.8fr 1.2fr 1fr 1fr;
		border-bottom: 1px solid #f2f2f2;
	}
	.td__wrap {
		a {
			font-family: Euclid Circular A;
			font-size: 14px;
			font-weight: 400;
			line-height: 22.4px;
			text-align: left;
			color: #0071AB;
			text-decoration: underline;
		}
	}
	.table-header {
		.tr {
			border-bottom: none;
			.td > p {
				border-radius: 6px;
				background: var(--greyscale-grey-10);
				padding: 2px 10px;
				height: 34px;
				display: flex;
				align-items: center;
				color: var(--greyscale-grey-600);
				font-size: 14px;
				font-weight: 400;
				line-height: normal;
			}
		}
	}

	.table-body {
		.td:last-child {
			//display: flex;
			//justify-content: center;
			//align-items: center;
		}
	}

	.td {
		padding: 17px 6px;

		&__wrap {
			margin-left: 6px;
		}

		&__text {
			/* styleName: Body/Body4 14 Regular; */
			font-family: 'Euclid Circular A', sans-serif;
			font-size: 14px;
			font-weight: 400;
			line-height: 22.4px;
			text-align: left;
		}
		&__text___data {
			/* styleName: Body/Body4 14 Regular; */
			font-family: Euclid Circular A;
			font-weight: 500;
			font-size: 14px;
			line-height: 22.4px;
			letter-spacing: 0px;

		}
		&__more {
			/* styleName: Body/Body6 12 Regular; */
			font-family: 'Euclid Circular A', sans-serif;
			font-size: 12px;
			font-weight: 400;
			line-height: 15.22px;
			text-align: left;
			color: var(--text-color2);
		}
	}

	.btn--icon {
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 6px;
		border: 1px solid var(--blue200);
		background: var(--blue100);
		box-shadow: 0 2px 6px rgba(33, 44, 99, 0.09);
		width: 38px;
		height: 38px;
		padding: 0;
	}

	min-height: 60vh;
}

.your-account-main {
	.table-block {
		.pagination-block {
			border-top: none;
		}
	}
}

@media (max-width: 1084px) {
	.table--your-account-main {
		.tr {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
		}
		.td:last-child {
			justify-content: flex-start !important;
			align-items: flex-start !important;
			flex-direction: column !important;
		}
	}

	.your-account-main {
		.table-footer {
			display: flex;
			flex-direction: column;
		}
	}
}
